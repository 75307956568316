<template>
  <div v-if="app" class="wrapper wrapper-nopadding page">
    <article>
      <div class="wrapper wrapper-mobilepadding">
        <div class="spacer-half">
          <Tags :tags="app.tags"/>
        </div>

        <h1>{{ app.title }}</h1>

        <p>{{ app.description }}</p>
      </div>

      <WPMigrateSQL />
    </article>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Tags from '../components/general/Tags.vue';

const WPMigrateSQL = () => import('./apps/WPMigrateSQL.vue');

export default {
  name: 'AppsPage',
  components: {
    Tags,
    WPMigrateSQL
  },
  computed: {
    ...mapGetters([
      'application'
    ]),
    app: function () {
      return this.application(this.$route.params.slug)
    },
  }
}
</script>

<style lang="scss" scoped>

</style>